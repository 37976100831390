import { render, staticRenderFns } from "./SupervisionSystem.vue?vue&type=template&id=6c0b9c44&scoped=true"
import script from "./SupervisionSystem.vue?vue&type=script&lang=ts&setup=true"
export * from "./SupervisionSystem.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SupervisionSystem.vue?vue&type=style&index=0&id=6c0b9c44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0b9c44",
  null
  
)

export default component.exports